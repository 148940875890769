'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/legacy/image';
import { Helmet } from 'react-helmet';
import cx from 'classnames';

import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide, SwiperClass } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { ScrollReveal } from '../ScrollReveal';
import { EmbeddedPost } from './EmbeddedPost';
import { Loader } from './Loader';

import arrowLeft from '@/images/arrow-left-black.svg';
import arrowRight from '@/images/arrow-right-black.svg';
import { usePageDataContext } from '~/context/PageData';

interface Post {
  link: string;
  thumb?: string;
  html?: string;
  api: string;
}

interface ISocialContentCarouselComponentFields {
  heading: string;
  subheading: string;
}

export function SocialContentCarousel({
  heading,
  subheading
}: ISocialContentCarouselComponentFields) {
  const { embeddedPosts } = usePageDataContext();
  const [isPostOpen, setPostOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | undefined>();
  const [selectedPostIndex, setSelectedPostIndex] = useState<
    number | undefined
  >();
  const [loadedThumbs, setLoadedThumbs] = useState<Record<string, boolean>>({});
  const [edges, setEdges] = useState({ left: 0, right: 0 });
  const swiperRef = useRef<SwiperRef>(null);

  if (!embeddedPosts) {
    return <></>;
  }

  const openPost = useCallback(
    (postIndex: number) => {
      const swiper = swiperRef.current?.swiper as SwiperClass;
      const slidesPerView = swiper.params.slidesPerView as number;
      const currentSlideIndex = swiper.realIndex ?? 0;
      const totalPosts = embeddedPosts.length;
      const newLeftEdgeSlideIndex =
        Math.abs((postIndex % totalPosts) + totalPosts) % totalPosts;
      const newRightEdgeSlideIndex = Math.abs(
        (newLeftEdgeSlideIndex + slidesPerView - 1) % totalPosts
      );
      setEdges({ left: newLeftEdgeSlideIndex, right: newRightEdgeSlideIndex });
      setPostOpen(true);
      setSelectedPost(embeddedPosts[postIndex]);
      setSelectedPostIndex(postIndex);

      if (newLeftEdgeSlideIndex !== currentSlideIndex) {
        const isNext = newLeftEdgeSlideIndex === currentSlideIndex + 1;
        const isLast = postIndex === edges.right;
        const direction = isNext || isLast ? 'next' : 'prev';

        // the following is an internal function of Swiper that
        // needs to be called to fix the loop
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        swiper.loopFix({ direction });
        swiper.update();
        swiper.slideToLoop(newLeftEdgeSlideIndex);
      }
    },
    [swiperRef.current, edges]
  );

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;

    if (swiper) {
      setEdges({
        left: 0,
        right: (swiper?.params.slidesPerView as number) - 1
      });
    }
  }, [swiperRef.current]);

  const openNewPost = (index: number) => {
    setPostOpen(false);
    openPost(index);
  };

  return (
    <ScrollReveal className="social-content-carousel">
      <Helmet>
        <script async defer src="https://www.instagram.com/embed.js" />
      </Helmet>
      <section className="flex w-full flex-col items-center">
        {subheading && <p className="text-base">{subheading}</p>}

        {heading && (
          <div className="px-5 tablet:flex tablet:w-full tablet:flex-col tablet:items-center tablet:justify-center tablet:self-stretch tablet:px-0">
            <h2 className="heading-2 mb-12 mt-4 text-center">{heading}</h2>
          </div>
        )}
        <div className="w-full">
          <Swiper
            ref={swiperRef}
            slidesPerView={'auto'}
            loop={true}
            loopPreventsSliding={true}
            centeredSlidesBounds={true}
            centeredSlides={true}
            className="h-[220px] tablet:h-[260px] desktop:h-[320px]"
            modules={[A11y, Navigation]}
            centerInsufficientSlides={true}
            navigation={{
              nextEl: '.video-button-next',
              prevEl: '.video-button-prev'
            }}
            onBreakpoint={() => {
              if (screen.availWidth < 720 && swiperRef.current) {
                swiperRef.current.swiper.params.spaceBetween = 16;
              } else if (screen.availWidth < 1080 && swiperRef.current) {
                swiperRef.current.swiper.params.spaceBetween = 24;
              } else if (screen.availWidth < 1440 && swiperRef.current) {
                swiperRef.current.swiper.params.spaceBetween = 20;
              }
              return null;
            }}
            breakpoints={{
              0: {
                spaceBetween: 16,
                initialSlide: 0
              },
              720: {
                spaceBetween: 24,
                initialSlide: 0
              },
              1080: {
                spaceBetween: 20,
                initialSlide: 2
              },
              1440: {
                spaceBetween: 24,
                initialSlide: 3
              }
            }}
          >
            {embeddedPosts?.map((post, index) => (
              <SwiperSlide
                key={post.link}
                className="relative !size-48 !transition-[width] !duration-300 tablet:!size-56 desktop:!size-[260px]"
                data-testid="social-content-carousel-slide"
              >
                {!post.thumb ? (
                  <Loader key={post.link} />
                ) : (
                  <div
                    className="absolute inset-0 m-auto aspect-square overflow-hidden rounded-[10px]"
                    onClick={() => openPost(index)}
                    onKeyUp={(e) => e.key === 'Enter' && openPost(index)}
                    role="button"
                    tabIndex={0}
                  >
                    <Image
                      src={post.thumb}
                      alt="post thumbnail"
                      layout="fill"
                      className={cx(
                        'w-inherit h-inherit cursor-pointer object-cover opacity-0 transition duration-300 ease-out hover:scale-110',
                        { '!opacity-100': loadedThumbs[post.thumb] }
                      )}
                      loading="lazy"
                      loader={({ src }) => src}
                      onLoadingComplete={() =>
                        setLoadedThumbs((prev) => ({
                          ...prev,
                          [post.thumb!]: true
                        }))
                      }
                      data-testid="social-content-carousel-thumbnail"
                    />
                    {!loadedThumbs[post.thumb] && <Loader />}
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <nav className="mt-12 flex w-full justify-end gap-6 px-5 tablet:px-10 desktop:gap-8 desktop:px-15">
            <button className="video-button-prev">
              <Image
                src={arrowLeft.src}
                width={arrowLeft.width}
                height={arrowLeft.height}
                alt="Left arrow"
              />
            </button>
            <button className="video-button-next">
              <Image
                src={arrowRight.src}
                width={arrowRight.width}
                height={arrowRight.height}
                alt="Right arrow"
              />
            </button>
          </nav>
        </div>
        <EmbeddedPost
          open={isPostOpen}
          html={selectedPost?.html}
          api={selectedPost?.api}
          index={selectedPostIndex}
          onClose={() => {
            setPostOpen(false);
            setSelectedPost(undefined);
            setSelectedPostIndex(undefined);
          }}
          openNewPost={openNewPost}
        />
      </section>
    </ScrollReveal>
  );
}
